<template>
  <div>
    <nav-bar fixed title="发票管理" @click-left="goBack" :left-arrow="isWeixin_status == false" right-text="添加"  @click-right="onClickRight" />
    <div class="invoice-con">
      <span style="margin-left:20px">左滑修改信息</span>
      <RadioGroup v-model="id">
        <SwipeCell v-for="(item, idx) in listArr" :key="idx">
          <div class="invoice-all">
            <div class="invoice-item" >
              <div class="invoice-item-top">
                <span>发票抬头 {{item.tax_name}}</span>
                <span>{{item.type == '1' ? '个人':'企业'}}</span>
              </div>
              <div class="invoice-item-bot">
                <span>电话 {{item.mobile}}</span>
                <span>{{item.create_time}}</span>
              </div>
            </div>
            <Radio :name="item.id" ></Radio>
          </div>
          <Button slot="right" square text="修改" class="delete-button" @click="lookSelect(item)"/>
        </SwipeCell>
      </RadioGroup>
      <Button type="primary" @click="openReceipt" color="#1989fa" class="bot-btn" block>开发票</Button>
    </div>
  </div>
</template>
<script>
import { NavBar, SwipeCell, Button, RadioGroup, Radio, Dialog } from "vant";
import CreditShop from "@/api/creditshop";
import User from "@/api/user";
export default {
  data() {
    return {
      listArr: [],
      id: 0
    };
  },
  components: {
    NavBar,
    SwipeCell,
    Button,
    RadioGroup,
    Radio,
    [Dialog.Component.name]: Dialog.Component,
  },
  mounted() {
    this.$nextTick(() => {
      this.getList();
    });
  },
  methods: {
    // 发票列表
    async getList() {
      try {
        const res = await CreditShop.ReceiptList();
        if (res.code == 200) {
          if (res.data.length > 0) {
            this.listArr = res.data;
            console.log(this.listArr);
          }
        }
      } catch (error) {
        this.$toast(error);
      }
    },
    lookSelect(item) {
      if (this.$route.query.hasOwnProperty("source")) {
        this.$router.go(-1);
        this.$store.commit("setInvoiceId", item.id);
      } else {
        this.$router.push({
          path: "/invoice",
          query: {
            InvoiceId: item.id
          }
        });
      }
    },
    // 开发票
    async openReceipt() {
      try {
        if (!this.id) this.$toast('请选择发票类型');
        const res = await User.addReceipt(this.$route.query.ordersn, this.id);
        if (res.code == 200) {
          Dialog.alert({title: '开票成功'});
        } else {
          this.$toast(res.msg);
        }
      } catch (error) {
        this.$toast(error);
      }
    },
    // 删除
    async deleteOption(id) {
      try {
        const res = await CreditShop.removeReceipt(id)
        if(res.code == 200) {
          this.getList();
        }
      } catch (error) {
        console.log(error)
      }
    },
    onClickRight() {
      this.$router.push("/invoice")
    }
  }
};
</script>
<style lang="scss" scoped>
.invoice-con {
  width: 100%;
  height: 100vh;
  padding: 50px 0;
  .invoice-all {
    width: 100%;
    height: 80px;
    margin-top: 10px;
    display: flex;
    padding-right: 20px;
    justify-content: space-between;
    background-color: #fff;
  }
  .bot-btn {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .invoice-item {
    width: 88%;
    height: 80px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    .invoice-item-top {
      width: 100%;
      height: 40px;
      padding: 0 20px;
      display: flex;
      font-size: 14px;
      color: #333333;
      align-items: center;
      border-bottom: 1px solid #dfdfdf;
      justify-content: space-between;
    }
    .invoice-item-bot {
      flex: 1;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .delete-button {
    height: 100%;
  }
}
</style>